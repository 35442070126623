import { useEffect, useState } from "react";
import { CartItemsContext } from "./CartItemsContext";
import {useUpdateCartMutation, useUpdateCheckOutMutation, useDeleteCartMutation,useGetCartQuery,useGetCartEventMutation,useUpdateCartRemoveItemMutation } from "../redux/service/cart"
import {useAuth0} from "@auth0/auth0-react";
const CartItemsProvider = (props) => {
    const {user} = useAuth0()
    const {data = {}, isLoading, isFetching,refetch} = useGetCartQuery({ query: user?.nickname, recentOnly: false })
    const [cartItems, setCartItems] = useState([])
    const [totalAmountOfItems, setTotalAmountOfItems] = useState(0)
    const [_updateCart,response] = useUpdateCartMutation()
    const [_updateCartRemove,_response] = useUpdateCartRemoveItemMutation()
    const [_deleteCart,resdelete] = useDeleteCartMutation()

    const [_checkout , responsecheckout] = useUpdateCheckOutMutation()
    const [_cart,rescart] = useGetCartEventMutation()

    useEffect(()=>{
        if(data?.content?.cart)  setCartItems(data?.content?.cart)
    },[isFetching,user])

    const addToCartHandler = async (item, quantity,detail) => {

        const { id , product_name , product_price,product_detail, product_image1,product_quality, size} = item;
        await _updateCart({id, product_name, product_price, product_image1,product_detail,detail:detail, product_quality, size,emp_id:user?.nickname })
        removeFromCartHandler(item)
        setCartItems((prevItems) => [...prevItems, {id, product_name, product_price, product_image1,product_detail,detail:detail, product_quality, size}])
    }

    const addToCartHandlerIndex = async (item, quantity,detail) => {
        const { id , product_name , product_price,product_detail, product_image1,product_quality, size} = item;
        console.log(item)
        alert('เพิ่มสินค้าลงตะกร้า')
       await _updateCart({id, product_name, product_price, product_image1,product_detail,detail:detail, product_quality:quantity, size,emp_id:user?.nickname })
       removeFromCartHandler(item)
      setCartItems((prevItems) => [...prevItems, {id, product_name, product_price, product_image1,product_detail:quantity,detail:detail, product_quality, size}])
    }

    const addToCartHandlerCart = async (item, quantity,detail) => {
        const { id,product_id , product_name , product_price,product_detail, product_image1,product_quality, size} = item;
        await _updateCart({id : product_id, product_name, product_price, product_image1,product_detail,detail:detail, product_quality:quantity, size,emp_id:user?.nickname })
        removeFromCartHandler(item)
        setCartItems((prevItems) => [...prevItems, {id : product_id, product_name, product_price, product_image1,product_detail:quantity,detail:detail, product_quality, size}])
    }

    const removeFromCartHandler = async (item) => {
        setCartItems(cartItems.filter((prevItem) => prevItem.id !== item.id))
        _cart({emp_id:user?.nickname}).unwrap()
            .then((data)=>{
                setCartItems(data?.content?.cart)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const removeFromCartHandler2 = async (item) => {
      //  setCartItems(cartItems.filter((prevItem) => prevItem.id !== item.id))
        _cart({emp_id:user?.nickname}).unwrap()
            .then((data)=>{
                setCartItems(data?.content?.cart)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const loadTocheckOutCart = async (status,signature,detailItem,detailAccessControl) => {
       await _checkout({status : status,sign_user:signature,emp_id:user?.nickname,detailItem:detailItem,detailAccessControl:detailAccessControl}).unwrap().then(()=>{console.log('ทำการสั่งสินค้าเรียบร้อย')})
        setCartItems([])
      //  window.location.reload();
    }

    const deteleItemOnCart = async (item) => {
        const { id , product_name , product_price,product_detail, product_image1,product_quality, size} = item;
        console.log(id)
        if(item.product_quality === 1){
            setCartItems(cartItems.filter((prevItem) => prevItem.id !== item.id))
            await _deleteCart(item)
        }else{
            await _updateCartRemove({id, product_name, product_price, product_image1,product_detail,detail:item.detail, product_quality:1, size,emp_id:user?.nickname ? user?.nickname : item.emp_code })
           await removeFromCartHandler2(item)
           // setCartItems((prevItems) => [...prevItems, {id, product_name, product_price, product_image1,product_detail:(item.product_quality-1),detail:item.detail, product_quality, size}])
        }

    }

    const calculateTotalAmount = (currentCartItems) => {
        let total = 0
        currentCartItems.forEach((item) => {
            total = total + (item.product_price * item.product_quality)
        })

        setTotalAmountOfItems(total)
    }

    const quantityHandler = (itemId, action) => {
        if(action === 'INC'){

        }
        else {

        }
    }

    useEffect(() => {
        calculateTotalAmount(cartItems)
    }, [cartItems])


    const cartItemCtx = {
        items: cartItems,
        totalAmount: totalAmountOfItems,
        addItem: addToCartHandler,
        removeItem: deteleItemOnCart,
        quantity: quantityHandler,
        checkout : loadTocheckOutCart,
        addItemInCart : addToCartHandlerIndex,
        addItemEventCart : addToCartHandlerCart
    }

    return (
        <CartItemsContext.Provider value={cartItemCtx}>
            {props.children}
        </CartItemsContext.Provider>
     );
}

export default CartItemsProvider;
