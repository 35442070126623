import './Control.css';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { coverdateNodashWithT } from '../../../config/helper';
import Cart from '../../Card/Cart/Cart';
import OrderDetail from "./OrderDetail";
import { useState,useEffect } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useGetOrderQuery, useGetOrderDetailMutation,useGetOrderStatusMutation } from "../../../redux/service/cart";
import { useAuth0 } from "@auth0/auth0-react";
import {
    TableContainer,
    Typography,
    Table,
    Paper,
} from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    width: '90%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '5px solid #478778',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    maxHeight: '90vh'
};

const style2 = {
    ...style,
    width: '70%',
    maxHeight: '90vh'
};

const Control = () => {
    const { user } = useAuth0();

    const [open, setOpen] = useState(false);
    const [status , setStatus] = useState(1)
    const [open2, setOpen2] = useState(false);
    const [_orderDetail] = useGetOrderDetailMutation();
    const [_useGetOrderStatusMutation] = useGetOrderStatusMutation()
    const [listDetail, setListDetail] = useState([]);
    const [datalist, setDatalist] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const { data = {}, isLoading, isFetching, refetch } = useGetOrderQuery({ query: user?.nickname, recentOnly: false });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    useEffect(()=>{
        if(data){
            setDatalist(data)
        }
    },[data])
    const getDataStatus = async (status) => {
        setStatus(status)
        const response = await _useGetOrderStatusMutation({ status: status,emp_id: user?.nickname }).unwrap();
        console.log(response)
        setDatalist(response)
    }

    const handleOpen2 = async (ordernumber) => {
        setOpen2(true);
        setLoadingMessage('Loading...');
        setListDetail([]);
        if (ordernumber) {
            try {
                const response = await _orderDetail({ order_number: ordernumber, emp_id: user?.nickname }).unwrap();
                setListDetail(response);
                setLoadingMessage(true);
            } catch (error) {
                setLoadingMessage('Failed to load order details.');
            }
        }
    };

    return (
        <div className="control__bar__container">
            <div className="controls__container">
                <div className="control" style={{ width: '300px', padding: '4px' }} onClick={handleOpen}>
                    <PersonOutlineIcon color="black" size="large" sx={{ width: '35px' }} />
                    {user?.nickname}
                </div>

                <div className="control">
                    <Cart />
                </div>
            </div>

            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className="cart__header">
                        <h2>สถานะคำสั่งซื้อ</h2>
                    </div>
                    <div className="tab__header">
                        <a style={{color:status===1 ?'blue':'',textDecoration:status===1 ?'underline':''}}  onClick={()=>getDataStatus(1)}>ทั้งหมด | </a>
                        <a style={{color:status===2 ?'blue':'',textDecoration:status===2 ?'underline':''}} onClick={()=>getDataStatus(2)}>พร้อมรับสินค้า | </a>
                        <a style={{color:status===3 ?'blue':'',textDecoration:status===3 ?'underline':''}} onClick={()=>getDataStatus(3)}>รอดำเนินการ | </a>
                        <a style={{color:status===4 ?'blue':'',textDecoration:status===4 ?'underline':''}} onClick={()=>getDataStatus(4)}>ยกเลิก</a>
                    </div>

                    <div className="cart__items__container">
                        <TableContainer component={Paper}  style={{height:'550px'}}>
                            <Box sx={{ p: 2 }}>
                                {datalist?.content?.cart?.slice(0, 10).map((item) => (
                                    <OrderDetail key={item.id} item={item} handleOpen2={handleOpen2} />
                                ))}
                            </Box>
                        </TableContainer>
                    </div>
                </Box>
            </Modal>

            <Modal open={open2} onClose={handleClose2} >
                <Box sx={style2}>
                    <div className="cart__header">
                        <h2>รายละเอียดสินค้า</h2>
                    </div>
                    <div className="cart__items__container">
                        <TableContainer component={Paper} style={{height:'350px'}}>
                            <Box sx={{ p: 2 }}>
                                {listDetail?.content?.cart.map((x) => (
                                    <Box key={x?.order_number} sx={{ mb: 2, border: '1px solid #ccc', borderRadius: '8px', p: 1 }}>
                                        <Typography variant="body2"><strong>เลขสั่งซื้อ : </strong>&nbsp;{x?.order_number}</Typography>
                                        <Typography variant="body2"><strong>สินค้า :</strong>&nbsp;{x?.hr_product?.product_name}</Typography>
                                        <Typography variant="body2"><strong>จำนวน : </strong>&nbsp;{x?.product_quality}</Typography>
                                        <Typography variant="body2"><strong>ชำระเงิน : </strong>&nbsp;{x?.status === '1' ? 'เงินสด' : x?.status === '2' ? 'ชำระผ่านเงินเดือน' : ''}</Typography>
                                        <Typography variant="body2"><strong>วันที่ซื้อ : </strong>&nbsp;{coverdateNodashWithT(x?.createdat)}</Typography>
                                        <Typography variant="body2"><strong>วันที่อนุมัติ : </strong>&nbsp;{coverdateNodashWithT(x?.approvedate)}</Typography>
                                        <Typography variant="body2"><strong>ราคา : </strong>  {(parseFloat(x?.product_price)).toLocaleString('th-TH', {
                                            style: 'currency',
                                            currency: 'THB'
                                        })}</Typography>
                                        <Typography variant="body2"><strong>หมายเหตุ : </strong>&nbsp;{x?.product_remark}</Typography>
                                    </Box>
                                ))}
                                {loadingMessage !== true && (
                                    <Typography variant="body2">{loadingMessage}</Typography>
                                )}
                            </Box>
                        </TableContainer>

                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default Control;
