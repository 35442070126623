import { useContext, useState } from 'react';
import './Detail.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CartItemsContext } from '../../../Context/CartItemsContext';
import { WishItemsContext } from '../../../Context/WishItemsContext';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Detail = (props) => {
    const [quantity, setQuantity] = useState(1);

    const cartItems = useContext(CartItemsContext)



    const handelQuantityIncrement = (event) => {
        setQuantity((prev) => prev += 1);
    };

    const handelQuantityDecrement = (event) => {
        if (quantity > 1) {
            setQuantity((prev) => prev -= 1);
        }
    };

    const handelAddToCart = () => {
        cartItems.addItemInCart(props.item, quantity, props.item.id)
    }



    return (
        <div className="product__detail__container">
            <div className="product__detail">
                <div className="product__main__detail">
                    <div className="product__name__main" style={{color:'#212121'}}>{props.item.product_name}</div>
                    <div className="product__detail__description" style={{color:'#212121'}}>{props.item.product_description} {props.item.product_code}</div>

                    <div className="product__price__detail" style={{ height: '60px',color:'#f57224',fontSize:'30px' }}>฿{props.item.product_price}</div>
                    <div className="product__detail__description product__quantity" style={{ justifyContent: 'left' }}> 
                    <IconButton onClick={handelQuantityDecrement}>
                            <RemoveCircleIcon fontSize='medium' />
                        </IconButton>
                        <div type="text" name="quantity" style={{color:'#333333',fontSize:20,lineHeight:'60%'}} className="quantity__input">{quantity}</div>
                        <IconButton onClick={handelQuantityIncrement}>
                             <AddCircleIcon />
                        </IconButton>
                       
                        </div>
                </div>
                <form onSubmit={handelAddToCart} className="product__form">
                    <div className="product__quantity__and__size">
                        {/* <div className="product__quantity" style={{ justifyContent: 'left' }}>
                            <IconButton onClick={handelQuantityIncrement}>
                                <AddCircleIcon />
                            </IconButton>
                            <div type="text" name="quantity" className="quantity__input">{quantity}</div>
                            <IconButton onClick={handelQuantityDecrement}>
                                <RemoveCircleIcon fontSize='medium' />
                            </IconButton>
                        </div> */}

                        {/*<div className="product size">*/}
                        {/*    <Box sx={{ minWidth: 100} }>*/}
                        {/*        /!*<FormControl fullWidth size="small">*!/*/}
                        {/*        /!*    <InputLabel>Size</InputLabel>*!/*/}
                        {/*        /!*    <Select*!/*/}
                        {/*        /!*    value={size}*!/*/}
                        {/*        /!*    label="size"*!/*/}
                        {/*        /!*    onChange={handleSizeChange}*!/*/}
                        {/*        /!*    >*!/*/}
                        {/*        /!*    {props.item.size.map((size) => <MenuItem value={size}>{size}</MenuItem>)}*!/*/}
                        {/*        /!*    </Select>*!/*/}
                        {/*        /!*</FormControl>*!/*/}
                        {/*    </Box>*/}
                        {/*</div>*/}
                    </div>
                    <div className="collect__item__actions">
                        <div className="add__cart__add__wish">
                            <div className="add__cart">
                                <Button variant="outlined" size="large" sx={[{ '&:hover': { backgroundColor: '#519384', borderColor: '#478778', borderWidth: '3px', color: 'white' }, minWidth: '100%', backgroundColor: '#478778', borderColor: '#478778', borderWidth: '3px', color: 'white' }]} onClick={handelAddToCart}>เพิ่มไปยังรถเข็น</Button>
                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default Detail;
