const json = {
     //url : 'http://localhost:3002/v2/api-portal',
    //url_image : 'http://localhost:3001/v1/image',
     url_image : 'http://172.16.11.97:3001/v1/image/',
     url : 'https://api.shop.rph.app/v2/api-portal',
     VITE_DOMAIN : 'rph.auth0.com',
     VITE_CLIENT_ID : 'FQYYSKFqr1c7pTbYmWprQpoMhYIgSVKR',
     VITE_CALLBACK_URL : 'https://shop.rph.app'
     //VITE_CALLBACK_URL : 'http://localhost:3000'
}

export default json;
