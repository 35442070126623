import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './example/counterSlice';
import { setupListeners } from '@reduxjs/toolkit/query'
import { pokemonApi } from './service/apiexample'
import {homepageApi} from "./service/homepage";
import {cartApi} from "./service/cart";
export const store = configureStore({
    reducer: {
        counter: counterReducer,
        [homepageApi.reducerPath] : homepageApi.reducer,
        [cartApi.reducerPath] : cartApi.reducer,
        [pokemonApi.reducerPath]: pokemonApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            pokemonApi.middleware,
            cartApi.middleware,
            homepageApi.middleware,
        ]),
})

setupListeners(store.dispatch)
