
import { useContext, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './CartCard.css';
import { CartItemsContext } from '../../../../Context/CartItemsContext';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import noimage from "../../../../asset/img/team-thumb-2.png"
import constants from "../../../../config/constants";
const CartCard = (props) => {
    let cartItems  = useContext(CartItemsContext)
    const handelRemoveItem = () => {
        cartItems.removeItem(props.item)

    }
    const handelAddToCart = () => {
        cartItems.addItemEventCart(props.item, 1, props.item.product_id)
    }

    const handleDecreaseQuantity = () => {
        // Assuming you have a function in CartItemsContext to decrease quantity
        cartItems.removeItem(props.item);
    };



    return (
        <>
       
        <div className='cart-item'>
            
            <div className="item-details" style={{textAlign:'left'}}>
        
                    <img src={props.item?.hr_product?.product_image1 ? props.item?.hr_product?.product_image1 :noimage} alt="item" className="item__image"/>
                
                <div className="item-info">
                <h2>{props.item?.hr_product?.product_name ? props.item?.hr_product?.product_name : props.item.product_name}</h2>
                <p>ราคา : {(parseFloat(props.item.product_price)).toLocaleString('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                })}</p>
                   <div className="quantity-controls">
                        
                        <p>จำนวน : 
                        <IconButton onClick={handleDecreaseQuantity}>
                            <RemoveCircleIcon />
                        </IconButton>{props.item.product_quality}
                        <IconButton onClick={handelAddToCart}>
                            <AddCircleIcon />
                        </IconButton>
                        </p>
                        
                    </div>
                </div>
            </div>
    
            <div class="item-total">
                <p style={{paddingLeft:'40%',textAlign:'left'}}>ทั้งหมด: {(parseFloat(props.item.product_price)*props.item.product_quality).toLocaleString('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                })}
                 {/* <IconButton style={{marginBottom:4}}>
                    <HighlightOffIcon onClick={handelRemoveItem}/>
                </IconButton> */}
                </p>
            </div>
            
        </div>
        </>
     );
}

export default CartCard;
