import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import constants from "../../config/constants";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
function User() {
    const {user} = useAuth0();
    return user?.nickname
}
export const homepageApi = createApi({
    reducerPath: 'homepageApi',
    baseQuery: fetchBaseQuery({ baseUrl:constants.url }),
    endpoints: (builder) => ({
        getProduct: builder.query({
            query: (body) => ({
                url: "/hr/product",
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.query,
                },
            }),
        }),
        getProductById: builder.query({
            query: (body) => ({
                url: `/hr/product?id=${body.id}`,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),
        getProductByCategory: builder.query({
            query: (body) => ({
                url: `/hr/product-by-category?id=${body.id}&gender=${body.gender}&size=${body.size}`,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),

    }),
});

export const {
    useGetProductQuery,
    useGetProductByIdQuery,
    useGetKindDetailMutation,
    useGetProductByCategoryQuery
} = homepageApi;
