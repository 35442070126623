import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import contatns from '../config/constants'
const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    return (
        <Auth0Provider
            domain={contatns.VITE_DOMAIN}
            clientId={contatns.VITE_CLIENT_ID}
            authorizationParams={{
                redirect_uri: contatns.VITE_CALLBACK_URL,
                // audience: `https://${import.meta.env.VITE_DOMAIN}/api/v2/`,
                // scope: "read:current_user read:users",
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;
