import React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { coverdateNodashWithT } from "../../../config/helper";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useRemoveOrderMutation } from "../../../redux/service/cart";
import { useAuth0 } from "@auth0/auth0-react";
const OrderTable = (props) => {
  const { user } = useAuth0();
  const [_removeOrder] = useRemoveOrderMutation();
  const theme = useTheme();
  const getOrderByDetail = async () => {
    props.handleOpen2(props.item?.sequence_number)
  }
  const removeOrder = async (sequence_number) => {

    const confirmDelete = window.confirm('ยืนยันยกเลิกคำสั่งซื้อ ?');
    console.log(sequence_number)
    if (confirmDelete) {
      const response = await _removeOrder({ sequence_number: sequence_number,emp_id: user?.nickname }).unwrap();
        alert('ยกเลิกคำสั่งซื้อสำเร็จ')
        window.location.reload()
    }
  }

  return (

    <Box key={props.item.id} sx={{ mb: 2, border: '1px solid #ccc', borderRadius: '8px', p: 1 }}>
      <Typography variant="body2"><strong>เลขสั่งซื้อ : </strong>&nbsp;{props.item.order_number}</Typography>
      <Typography variant="body2"><strong>ชำระเงิน : </strong>&nbsp;{props.item?.status === '1' ? 'เงินสด' : props.item?.status === '2' ? 'ชำระผ่านเงินเดือน' : ''}</Typography>
      <Typography variant="body2"><strong>วันที่ซื้อ : </strong>&nbsp;{coverdateNodashWithT(props.item?.createdat)}</Typography>
      <Typography variant="body2"><strong>วันที่อนุมัติ : </strong>&nbsp;{coverdateNodashWithT(props.item?.approvedate)}</Typography>
      <Typography variant="body2"><strong>วันที่นัดรับ : </strong>&nbsp;{coverdateNodashWithT(props.item?.receive_date)}</Typography>
      <Typography variant="body2"><strong>ผู้อนุมัติ : </strong>&nbsp;{props.item?.approve_name}</Typography>
      <Typography variant="body2"><strong>ยอดทั้งหมด : </strong>{(parseFloat(props.item?.total_amount)).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB'
      })}</Typography>
      <Typography variant="body2" ><strong>สถานะ:</strong><span className={props.item?.approve === 'W' ? 'text-info' : props.item?.approve === 'X' ? 'text-danger' : 'text-success'}> {props.item?.approve === 'W' ? 'รอดำเนินการ' : props.item?.approve === 'X' ? 'ยกเลิก' : 'สำเร็จ'}   <ManageSearchIcon color="info" cursor={'pointer'} size="large" sx={{ width: '35px' }} onClick={getOrderByDetail} /></span></Typography>
      <Typography variant="body2" ><span onClick={()=>removeOrder(props.item?.sequence_number)} className={'text-danger'} style={{ paddingLeft: '0px', textDecoration: 'underline', display: props.item?.approve === 'W' ? '' : 'none' }}>ยกเลิกคำสั่งซื้อ</span></Typography>

      {/* Add more item details as needed */}
    </Box>
  );
};

export default OrderTable;
