import Carousel from 'react-bootstrap/Carousel';
import './ItemCarousel.css'
import constants from "../../../config/constants";

const ProductCarousel = (props) => {
    return (
        <div className="product__carousel__container">
            <div className="product__carousel">
                <Carousel>
                    <Carousel.Item style={{display: props.item.product_image1 ? '' : 'none'}}>
                        <div className="carousel__image__container">
                            <img className="carousel__image"  src={props.item.product_image1} alt="item"/>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item style={{display : props.item.product_image2? '' : 'none'}}>
                        <div className="carousel__image__container" >
                            <img className="carousel__image"  src={props.item.product_image2} alt="item"/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item style={{display : props.item.product_image3? '' : 'none'}}>
                        <div className="carousel__image__container" >
                            <img className="carousel__image"  src={props.item.product_image3} alt="item"/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item style={{display : props.item.product_image4? '' : 'none'}}>
                        <div className="carousel__image__container" >
                            <img className="carousel__image"  src={props.item.product_image4} alt="item"/>
                        </div>
                    </Carousel.Item>


                </Carousel>
            </div>
        </div>
    );
}

export default ProductCarousel;
