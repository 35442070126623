import './NavBrand.css'
import { Link } from 'react-router-dom';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Cart from "../../Card/Cart/Cart";

const NavBrand = () => {
    return (
        <div href="#home" className='navbrand__container'>
           <h1 className='navbrand'>
               <Link to="/">RPH</Link>
            </h1>

        </div>
     );
}

export default NavBrand;
