import {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Route, Routes} from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from '../routes/Home';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ManageAccount from '../components/Account/ManageAccount/ManageAccount';
import MyAccount from '../components/Account/MyAccount/MyAccount';
import Shop from '../components/Shop/Shop';
import ItemView from '../routes/ItemView';
import CategoryView from '../routes/CategoryView';
import SearchView from '../routes/Search';
import CartItemsProvider from '../Context/CartItemsProvider';
import Login from '../components/Authentication/Login/Login';
import Register from '../components/Authentication/Register/Register';
import Wishlist from '../components/Wishlist';
import DrawerNav from '../components/Nav/DrawerNav/DrawerNav';
import Checkout from '../components/Checkout/Checkout';
import SearchProvider from '../Context/SearchProvider';
import AuthProvider from '../components/AuthProvider'
import AuthGuard from '../components/AuthGuard'
import {useAuth0} from "@auth0/auth0-react";

function App() {
    const {user} = useAuth0();
    useEffect(() => {
        localStorage.setItem('access_token',user?.nickname)
    }, [user?.nickname])

    return (
        <Router>
            <AuthProvider>
                <CartItemsProvider >
                    <SearchProvider>

                        <Header/>
                        <Routes>
                            <Route element={<AuthGuard/>}>
                                <Route index element={<Shop/>}/>
                                <Route path="/account">
                                    <Route path="me" element={<MyAccount/>}/>
                                    <Route path="manage" element={<ManageAccount/>}/>
                                    <Route path="login" element={<Login/>}/>
                                    <Route path="register" element={<Register/>}/>
                                    <Route path="*" element={<Login/>}/>
                                </Route>
                                <Route path="/shop" element={<Shop/>}/>
                                <Route path="/category">
                                    <Route path=":id/:name/:gender/:size" element={<Shop/>}/>
                                </Route>
                                <Route path="/item">
                                    <Route path="/item/rph">
                                        <Route path=":id" element={<ItemView/>}/>
                                    </Route>
                                    <Route path="/item/women">
                                        <Route path=":id" element={<ItemView/>}/>
                                    </Route>
                                    <Route path="/item/kids">
                                        <Route path=":id" element={<ItemView/>}/>
                                    </Route>
                                    <Route path="/item/featured">
                                        <Route path=":id" element={<ItemView/>}/>
                                    </Route>
                                </Route>
                                <Route path="/wishlist" element={<Wishlist/>}/>
                                <Route path="/search/*" element={<SearchView/>}/>
                            </Route>
                        </Routes>
                        <Footer/>
                        {/*<Routes>*/}
                        {/*    <Route path="/admin" element={<Wishlist/>}/>*/}
                        {/*</Routes>*/}


                    </SearchProvider>
                </CartItemsProvider>
            </AuthProvider>
        </Router>
    )
        ;
}

export default App;
