import {Fragment, useContext, useEffect, useState} from 'react';
import {CartItemsContext} from '../../../Context/CartItemsContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import CartCard from './CartCard/CartCard';
import './Cart.css'
import Button from '@mui/material/Button';
import SignatureCanvas from 'react-signature-canvas'
import {useGetUserQuery, useUpdateAddressMutation} from "../../../redux/service/cart";
import {useAuth0} from "@auth0/auth0-react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    width: '90%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '5px solid #478778',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
};
// ตะกร้าสินค้า
const Cart = () => {
    let sigPad = {}
    const {user} = useAuth0();
    const [open, setOpen] = useState(false);
    const [animate, setAnimate] = useState('')
    const [openAddress, setOpenAddress] = useState(false);
    const [detailCar, setDetailCar] = useState({brand : '',model:'',color:'',plate:'',brand1 : '',model1:'',color1:'',plate1:''})
    const [detailAccesscControl, setDetailAccesscControl] = useState('')
    const [conditionCar, setConditionCar] = useState(0)
    const [accessControl, setAccessControl] = useState(0)
    const [address, setAddress] = useState('')
    const [telephone, setTelephone] = useState('')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpenAddress(false);
    const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
    const handleCheckoutOpen = () => setOpenCheckoutModal(true);
    const handleCheckoutClose = () => setOpenCheckoutModal(false);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null)
    const {data = {}, isLoading, isFetching, refetch} = useGetUserQuery({query: user?.nickname, recentOnly: false})
    const [_updateAddress] = useUpdateAddressMutation()
    const cartItems = useContext(CartItemsContext);
    const sigClear = () => {
        sigPad.clear()
        setTrimmedDataURL(null)
    }
    const sigTrim = async (sequence_number, approve) => {
        console.log(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    }

    const [position, setPosition] = useState(0);


    useEffect(() => {
        setAnimate('')
        if (data?.content?.user) {
            if (!data?.content?.user?.address || !data?.content?.user?.telephone) {
                setOpenAddress(true)
                setAddress(data?.content?.user?.address)
                setTelephone(data?.content?.user?.telephone)
            } else {
                setAddress(data?.content?.user?.address)
                setTelephone(data?.content?.user?.telephone)
            }
        }
    }, [isFetching])

    useEffect(() => {
        //  console.log(cartItems.items.length)

        setConditionCar(cartItems.items.filter(item => item.product_id === 2).length)
        setAccessControl(cartItems.items.filter(item => item.product_id === 1).length)

        setAnimate('animation')
        setTimeout(() => {
            if (cartItems.items.length) {
                setAnimate('')
            }
        }, 2500)
    }, [cartItems])

    const handleCheckoutPay = async () => {
        setOpen(false)
        let errText =  ''

        if(conditionCar >=1 && (!detailCar.brand || !detailCar.model || !detailCar.color || !detailCar.plate)){
            errText+='กรุณา ระบุยี่ห้อรถในรายละเอียดบัตรเข้า-ออกรถยนต์ ยี่ห้อ รุ่น สี ป้ายทะเบียน\n'
        }
        if(accessControl >=1 && !detailAccesscControl){

            errText+='กรุณา ระบุรายละเอียดบัตร access control (โปรดขอเลขบัตรก่อนคีย์ซื้อ)\n'
        }
        if(sigPad.getTrimmedCanvas().toDataURL('image/png').length < 200){
            errText+='กรุณา เซ็นยืนยันรายการสั่งซื้อ\n'
        }

        if(errText){
            alert(errText)
            setOpen(true)
            return false
        }
        // if(conditionCar >= 1 || accessControl >= 1 ){
        //     if(!detailCar || !detailAccesscControl){
        //         alert('กรุณา ระบุยี่ห้อรถในรายละเอียดบัตรเข้า-ออกรถยนต์ หรือ ระบุรายละเอียดบัตร access control')
        //         setOpen(true)
        //         return false
        //     }
        // }
        if (cartItems.totalAmount > 0 && sigPad.getTrimmedCanvas().toDataURL('image/png').length > 200) {
            const config = {
                reason: 'checkout',
                amount: cartItems.totalAmount
            }
            handleCheckoutOpen()
            cartItems.checkout(1, sigPad.getTrimmedCanvas().toDataURL('image/png'), detailCar,detailAccesscControl)
        } else {
            // if (sigPad.getTrimmedCanvas().toDataURL('image/png').length < 200) {
            //     alert('กรุณาเซ็นยืนยันรายการสั่งซื้อ')
            //     setOpen(true)
            // }
            return
        }
    }
    const updateAddress = async () => {
        _updateAddress({emp_id: user?.nickname, address: address, telephone: telephone}).unwrap()
            .then((data) => {
                setOpenAddress(false)
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleCheckoutRollout = async () => {
        setOpen(false)
        let errText =  ''
        if(conditionCar >=1 && (!detailCar.brand || !detailCar.model || !detailCar.color || !detailCar.plate)){
            errText+='กรุณา ระบุยี่ห้อรถในรายละเอียดบัตรเข้า-ออกรถยนต์ ยี่ห้อ รุ่น สี ป้ายทะเบียน\n'
        }
        if(accessControl >=1 && !detailAccesscControl){
            errText+='กรุณา ระบุรายละเอียดบัตร access control\n'
        }
        if(sigPad.getTrimmedCanvas().toDataURL('image/png').length < 200){
            errText+='กรุณา เซ็นยืนยันรายการสั่งซื้อ\n'
        }

        if(errText){
            alert(errText)
            setOpen(true)
            return false
        }

        // if(conditionCar >= 1 || accessControl >= 1 ){
        //     if(!detailCar || !detailAccesscControl){
        //         alert('กรุณา ระบุยี่ห้อรถในรายละเอียดบัตรเข้า-ออกรถยนต์ หรือ ระบุรายละเอียดบัตร access control')
        //         setOpen(true)
        //         return false
        //     }
        // }
        if (cartItems.totalAmount > 0 && sigPad.getTrimmedCanvas().toDataURL('image/png').length > 200) {
            const config = {
                reason: 'checkout',
                amount: cartItems.totalAmount
            }
            handleCheckoutOpen()
            cartItems.checkout(2, sigPad.getTrimmedCanvas().toDataURL('image/png'), detailCar,detailAccesscControl)
        } else {
            // if (sigPad.getTrimmedCanvas().toDataURL('image/png').length < 200) {
            //     alert('กรุณาเซ็นยืนยันรายการสั่งซื้อ')
            //     setOpen(true)
            // }
            return
        }
    }

    return (
        <Fragment>
            <Badge badgeContent={cartItems.items.length} color="error" onClick={handleOpen}>
                <ShoppingCartIcon color="black" sx={{width: '35px'}} className={animate}/>
            </Badge>
            <Modal
                open={openAddress}
                onClose={handleClose2}
            >
                <Box sx={style}>
                    <div className="cart__header">
                        <h4>กรุณาระบุที่อยู่ตามบัตรประชาชนและเบอร์โทรศัพท์ก่อนสั่งสินค้า</h4>
                    </div>
                    <input type={'text'} className="search__form__input" value={address}
                           onChange={(e) => setAddress(e.target.value)} placeholder={'กรุณาระบุที่อยู่ก่อนสั่งสินค้า'}/>
                    <br/>
                    <br/>
                    <input type={'number'} maxLength={10} className="search__form__input" value={telephone}
                           onChange={(e) => setTelephone(e.target.value)}
                           placeholder={'กรุณาระบุเบอร์โทรศัพท์ที่สามารถติดต่อได้'}/>
                    <br/>
                    <Button color={'success'} onClick={() => updateAddress()} style={{float: 'right'}}>บันทึก</Button>
                </Box>

            </Modal>
            <Modal
                open={open}
                onClose={handleClose}
                style={{marginTop: '-10%'}}
            >
                <Box sx={style}>
                    <div className="cart__header">
                        <h2>ตะกร้าสินค้า</h2>
                    </div>
                    <div className="cart__items__container">
                        <div className="cartItems" style={{maxHeight: '550px'}}>
                            {cartItems.items.length === 0 ?
                                <div className="cart__empty"> ยังไม่มีสินค้าในรถเข็น</div> :
                                <>
                                    <div className="shop__cart__items">
                                        {cartItems.items.map((item) => <CartCard key={item.id} item={item}/>)}
                                    </div>
                                    <div className="options"
                                         style={{display: cartItems.items.filter(item => item.product_id === 2).length >= 1 ? '' : 'none'}}>
                                        <p style={{
                                            fontSize: 14,
                                            marginBottom:0,
                                            marginTop: '-40px',
                                            color:'red',
                                            borderRadius:5
                                        }}>คันที่ 1 เพิ่มรายละเอียดบัตรเข้า-ออกรถยนต์ *</p>
                                        <input
                                            style={{marginRight:4,marginBottom:4,width:'48%'}}
                                                  placeholder='ยี่ห้อ'
                                                  onChange={(e) => setDetailCar({...detailCar,brand :e.target.value})}></input>
                                        <input
                                            style={{width:'48%'}}
                                            placeholder='รุ่น'
                                            onChange={(e) => setDetailCar({...detailCar,model:e.target.value})}></input>
                                        <input
                                            style={{marginRight:4,marginBottom:4,width:'48%'}}
                                            placeholder='สี'
                                            onChange={(e) => setDetailCar({...detailCar,color: e.target.value})}></input>
                                        <input
                                            style={{width:'48%'}}
                                            placeholder='เลขทะเบียนรถ'
                                            onChange={(e) => setDetailCar({...detailCar,plate:e.target.value})}></input>
                                    </div>
                                    <div className="options"
                                         style={{display: cartItems.items.filter(item => item.product_id === 2).length >= 1 ? '' : 'none'}}>
                                        <p style={{
                                            fontSize: 14,
                                            marginBottom:0,

                                            borderRadius:5
                                        }}>คันที่ 2 เพิ่มรายละเอียดบัตรเข้า-ออกรถยนต์ *</p>
                                        <input
                                            style={{marginRight:4,marginBottom:4,width:'48%'}}
                                            placeholder='ยี่ห้อ'
                                            onChange={(e) => setDetailCar({...detailCar,brand1 :e.target.value})}></input>
                                        <input
                                            style={{width:'48%'}}
                                            placeholder='รุ่น'
                                            onChange={(e) => setDetailCar({...detailCar,model1:e.target.value})}></input>
                                        <input
                                            style={{marginRight:4,marginBottom:4,width:'48%'}}
                                            placeholder='สี'
                                            onChange={(e) => setDetailCar({...detailCar,color1: e.target.value})}></input>
                                        <input
                                            style={{width:'48%'}}
                                            placeholder='เลขทะเบียนรถ'
                                            onChange={(e) => setDetailCar({...detailCar,plate1:e.target.value})}></input>
                                    </div>

                                    <div className="options"
                                         style={{display: cartItems.items.filter(item => item.product_id === 1).length >= 1 ? '' : 'none'}}>
                                        <p style={{
                                            fontSize: 14,
                                            marginTop: '10px',
                                            color:'red',
                                            borderRadius:5
                                        }}>เพิ่มรายละเอียด access control (โปรดขอเลขบัตรก่อนคีย์ซื้อ) *</p>
                                        <textarea style={{width: '100%', marginTop: '-14px'}}
                                                  placeholder='ขอเข้าชั้น 4 ห้อง (โปรดขอเลขบัตรก่อนคีย์ซื้อ)'
                                                  onChange={(e) => setDetailAccesscControl(e.target.value)}></textarea>
                                    </div>
                                </>
                            }
                            {cartItems.items.length > 0 &&
                                <>

                                    <div className="options">
                                        <div className="total__amount">
                                            <div className="total__amount__label">รวมทั้งหมด</div>
                                            <div
                                                className="total__amount__value"> {(parseFloat(cartItems.totalAmount)).toLocaleString('th-TH', {
                                                style: 'currency',
                                                currency: 'THB'
                                            })}</div>


                                        </div>

                                        <div className="options">
                                            <div className="total__amount">
                                                <div className="total__amount__label">ที่อยู่
                                                    : {data?.content?.user?.address} <br/> โทร
                                                    : {data?.content?.user?.telephone}
                                                    <span style={{
                                                        color: '#5998f5',
                                                        fontSize: '16px',
                                                        textDecoration: 'underline',
                                                        paddingLeft: '10px'
                                                    }} onClick={() => setOpenAddress(true)}>แก้ไข</span></div>
                                            </div>
                                        </div>
                                        <div className="cartItems signpad">
                                            <h6 style={{padding: '10px'}}>เซ็นยืนยัน</h6>
                                            <SignatureCanvas penColor='black'
                                                             canvasProps={{
                                                                 width: 500,
                                                                 height: 200,
                                                                 className: 'sigCanvas'
                                                             }}
                                                             ref={(ref) => {
                                                                 sigPad = ref
                                                             }}
                                            />

                                        </div>

                                        <div className="checkout">
                                            <span style={{
                                                color: '#5998f5',
                                                fontSize: '12px',
                                                textDecoration: 'underline'
                                            }} onClick={() => sigClear()}>Clear</span>
                                            <Button variant="outlined" style={{margin: '8px', fontSize: '12px'}}
                                                    onClick={handleCheckoutPay}>ชำระเงินสด</Button>
                                            <Button variant="outlined" style={{fontSize: '12px'}}
                                                    onClick={handleCheckoutRollout}>ตัดผ่านเงินเดือน</Button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openCheckoutModal}
                onClose={handleCheckoutClose}
            >
                <Box sx={style}>
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <h2>ดำเนินการสั่งสินค้าเรียบร้อย</h2>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
}

export default Cart;
