import { useEffect, useState } from 'react';
import { TabTitle } from '../../utils/General';
import ShopCategory from './Container/ShopCategory';
import './Shop.css';
import ReactLoading from 'react-loading';
import {useGetProductQuery, useGetProductByCategoryQuery} from "../../redux/service/homepage";
import {useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
const Shop = () => {
    const param = useParams()
    const {user} = useAuth0()
    const {data = {}, isLoading, isFetching,refetch} = useGetProductQuery({ query: user.nickname, recentOnly: false })
    const productByCategory = useGetProductByCategoryQuery({id : param.id , gender : param.gender , size : param.size,emp_id : user.nickname})
    TabTitle("Shop - RPH")


    const [ menItems, setMenItems ] = useState([])
    const [ menItemsS, setMenItemsS ] = useState([])
    const [itemCategory , setItemCategory] = useState([])
    const [ loading , setLoading ] = useState(true)


    useEffect(() => {
        setMenItems(data?.content?.product)
        setMenItemsS(data?.content?.product_specail)
        if(productByCategory?.data?.content?.product){
            if(productByCategory?.data?.content?.product?.length === 0){
                alert('ไม่พบสินค้า')
            }
            setItemCategory(productByCategory?.data?.content?.product)
        }
        setLoading(isFetching)

        window.scrollTo(0, 0)


    }, [isFetching,isLoading])

    return (
        <div className="shop__contianer">

            {loading && <ReactLoading type="balls" color='#478778'  height={100} width={100} className='container h-100 w-10 justify-self-center align-self-center m-auto'/>}
            {menItems ? <ShopCategory name={param.name ? param.name : 'สินค้า'} key="Products" items={menItems} items2={menItemsS} items3={itemCategory}/> : ''}
            {/*{womenItems && <ShopCategory name="Women" key="women" items={womenItems}/>}*/}
            {/*{kidsItems && <ShopCategory name="Kids" key="kids" items={kidsItems}/>}*/}
        </div>
     );
}

export default Shop;
