import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import constants from "../../config/constants";

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({ baseUrl:constants.url }),
    endpoints: (builder) => ({
        getCart : builder.query({
            query: (body) => {
                console.log('cart',body)
                return ({
                    url: "/hr/cart",
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: body.query,
                    },
                })
            },
        }),
        getOrder : builder.query({
            query: (body) => {
                console.log('order',body.query)
                return ({
                    url: "/hr/order",
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: body.query,
                    },
                })
            },
        }),
        getOrderStatus : builder.mutation({
            query: (body) => {
                console.log('order222222',body.emp_id)
                return ({
                    url: `/hr/order?status=${body.status}`,
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: body.emp_id,
                    },
                })
            },
        }),
        getOrderDetail: builder.mutation({
            query: (query) => ({
                url: "/hr/order-detail?sequence_number="+query.order_number,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: query.emp_id,
                },
            }),
        }),
        getCartEvent: builder.mutation({
            query: (body) => ({
                url: "/hr/cart",
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),
        removeOrder: builder.mutation({
            query: (body) => ({
                url: "/hr/order-remove",
                method: "post",
                body ,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),
        updateCart: builder.mutation({
            query: (body) => ({
                url: "/hr/cart",
                method: "POST",
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),
        updateCartRemoveItem: builder.mutation({
            query: (body) => ({
                url: "/hr/cart-remove",
                method: "POST",
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_id,
                },
            }),
        }),
        updateCheckOut: builder.mutation({
            query: (body) => ({
                url: `/hr/checkout`,
                method: "POST",
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:body.emp_id,
                },
            }),
        }),
        deleteCart: builder.mutation({
            query: (body) => ({
                url: `/hr/cart-delete`,
                method: "delete",
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: body.emp_code,
                },
            }),
        }),

        getUser : builder.query({
            query: (body) => {
                return ({
                    url: `/hr/user?emp_id=${body.query}`,
                    method: "get",
                    headers: {
                        Authorization:body.query,
                        'Content-Type': 'application/json'
                    },
                })
            },
        }),
        updateAddress : builder.mutation({
            query: (body) => ({
                url: `/hr/backend/user`,
                method: "post",
                body,
                headers: {
                    Authorization: body.emp_id,
                    'Content-Type': 'application/json'
                },
            }),
        }),


    }),
});

export const {
    useUpdateCartMutation,
    useGetCartEventMutation,
    useGetCartQuery,
    useGetOrderQuery,
    useGetOrderStatusMutation,
    useGetOrderDetailMutation,
    useUpdateCheckOutMutation,
    useDeleteCartMutation,
    useGetUserQuery,
    useUpdateAddressMutation,
    useUpdateCartRemoveItemMutation,
    useRemoveOrderMutation
} = cartApi;
