import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";

const AuthGuard = () => {

    const { user } = useAuth0();
    console.log("user", user);
    const Component = withAuthenticationRequired(Outlet, {
        // onRedirecting: () => <Spin spinning fullscreen />,
    });
    // if (user) {
    //   //  return (window.location.href = "https://staff.rph.co.th/home");
    // }
    return <Component />;
};

export default AuthGuard;
