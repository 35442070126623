import {Row,Col} from 'react-bootstrap';
import ItemCard from "../../Card/ItemCard/ItemCard";
import ItemCardS from "../../Card/ItemCard/ItemCardS";
import './ShopCategory.css'
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const ShopCategory = (props) => {
    const param = useParams()
    useEffect(() => {
        
    }, [props.items2])
    return (

        <div className="shop__category__container">

            <div className="shop__category__header">
                <div className="shop__category__header__big">
                    <div className="shop__category__head">
                        <h2>{props.name}</h2>
                    </div>
                    <div className="shop__category__header__line"></div>
                </div>
                </div>
                <div className="shop__category__card__container">
                    <div className="shop__category__product__card">
                       <Row>

                            {!!! param.id  ? props.items.map((data) => <Col xs={12} sm={2}><ItemCard item={data} category={props.category}  /></Col>) : ''}
                            {!!! param.id  ? props.items2.map((data) => <Col xs={12} sm={2}><ItemCardS item={data} category={props.category}  /></Col>) : ''}
                           {param.id  ? props.items3.map((data) => <Col xs={12} sm={2}><ItemCard item={data} category={props.category}  /></Col>) : ''}
                       </Row>

                    </div>
            </div>
        </div>
     );
}

export default ShopCategory;
