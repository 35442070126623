import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Item from '../components/Item/Item';
import {useGetProductByIdQuery} from "../redux/service/homepage";
import {useAuth0} from "@auth0/auth0-react";
const ProductView = (props) => {
    const param = useParams()
    const {user} = useAuth0()
    const {data = {}, isLoading, isFetching} = useGetProductByIdQuery({id : param.id,emp_id : user?.nickname})
    const [ item, setItem ] = useState({})
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        setItem(data?.content?.product)
        window.scrollTo(0, 0)
        setLoading(isLoading)

    }, [param.id,isLoading])

    return (
            <div className="d-flex min-vh-100 w-100 justify-content-center align-items-center m-auto">
                {loading && <ReactLoading type="balls" color='#478778' height={100} width={100} className='m-auto'/>}
                {item && <Item item={item}/>}
            </div>
     );
}

export default ProductView;
