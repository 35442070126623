import './ItemCard.css';
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {CartItemsContext} from "../../../Context/CartItemsContext";
import {IconButton} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import NativeSelect from '@mui/material/NativeSelect';
import constants from "../../../config/constants"
import noimage from "../../../../src/asset/img/team-thumb-2.png"
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const ItemCard = (props) => {
    const [open, setOpen] = useState(false);
    const [gender, setGender] = useState('');
    const [size, setSize] = useState('');
    const [isHovered, setIsHovered] = useState(false)
    const cartItemsContext = useContext(CartItemsContext)
    const [detail, setDetail] = useState('')
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => setOpen(false);
    const handleGender = (event) => setGender(event.target.value)
    const handleSize = (event) => setSize(event.target.value)
    const actionPage = () => {
        if(!gender || !size){
            alert('กรุณาเลือก เพศ และ ไซด์')
        }else {
            window.location.href = `/category/${props.item?.id}/${props.item?.product_type_name}/${gender}/${size}`;
        }
    }
    return (

        <div className="product__card__card">
            <div className="product__card" onClick={handleOpen} style={{cursor:'pointer'}}>
                    <div className="product__image"
                         onMouseEnter={() => setIsHovered(true)}
                         onMouseLeave={() => setIsHovered(false)}
                    >
                        {props.item?.product_image1 ? <img src={props.item?.product_image1 ? props.item?.product_image1 : noimage} alt="item" className="product__img" style={{width:'auto',height:'auto'}}/> :
                            <img src={noimage} alt="item"  className="product__img" />}
                    </div>
                <div className="product__card__detail">
                        <div className="product__name">

                            {props.item?.product_type_name}

                        </div>

                    <div className="product__price">
                        {/*<Link to={`/category/${props.item?.id}/${props.item?.product_type_name}`}>*/}
                        <span>
                        <IconButton sx={{
                            borderRadius: '20px',
                            width: '40px',
                            height: '40px'
                        }}
                                    onClick={handleOpen}
                        >
                            <AddShoppingCartIcon sx={{width: '22px', height: '22px', color: 'black'}}
                                                 onClick={handleOpen}/>
                        </IconButton>
                        </span>
                        {/*</Link>*/}
                    </div>

                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        กรุณาเลือก
                    </Typography>
                    <br/>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">เพศ</InputLabel>
                    <NativeSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="เพศ"
                        style={{width:'300px'}}
                        onChange={handleGender}

                    >
                        <option value="">
                            <em>เลือกเพศ</em>
                        </option>
                        <option value={'M'}>ชาย</option>
                        <option value={'F'}>หญิง</option>
                    </NativeSelect>
                    <br/>
                    <br/>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">ไซด์</InputLabel>
                    <NativeSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="ไซด์"
                        onChange={handleSize}
                        style={{width:'300px'}}

                    >
                        <option value="">
                            <em>เลือกไซด์</em>
                        </option>
                        <option value={'S'}>S</option>
                        <option value={'M'}>M</option>
                        <option value={'L'}>L</option>
                        <option value={'XL'}>XL</option>
                        <option value={'2XL'}>2XL</option>
                        <option value={'3XL'}>3XL</option>
                        <option value={'3XL'}>3XL</option>
                        <option value={'4XL'}>4XL</option>
                    </NativeSelect>
                    <br/>
                    <br/>
                    <Button variant="contained" onClick={actionPage}>เลือก</Button>
                </Box>
            </Modal>

        </div>
    );
}

export default ItemCard;
