const coverdateNodash = (date= new Date()) => {
  var year        = date.substring(0,4);
  var month       = date.substring(4,6);
  var day         = date.substring(6,8);
  return year+'-'+month+'-'+day
}

const coverdateNodashDMY = (date= new Date()) => {
  var year        = date.substring(0,4);
  var month       = date.substring(4,6);
  var day         = date.substring(6,8);
  return day+'-'+month+'-'+year
}

const coverdateNodashWithT = (date= new Date()) => {
  if(date){
    date = date.split('T')
    date = date[0]
    var year        = date.substring(0,4);
    var month       = date.substring(5,7);
    var day         = date.substring(8,10);
    return day+'/'+month+'/'+year
  }else{
    return ''
  }

}

const numberformat = (number) => {

  return parseInt(number) > 0  ? (parseFloat(number)).toLocaleString('th-TH', {
    style: 'currency',
    currency: 'THB'
  }) : '฿0.00'

}

const numberformatReport = (number) => {

  return parseInt(number) > 0  ? (parseFloat(number)).toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00'

}

module.exports = {
  coverdateNodash,
  coverdateNodashWithT,
  numberformat,
  numberformatReport,
  coverdateNodashDMY

};
