import './ItemCard.css';
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {CartItemsContext} from "../../../Context/CartItemsContext";
import {IconButton} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Box from "@mui/material/Box";
import {Form,Button} from 'react-bootstrap';
import Modal from "@mui/material/Modal";
import constants from "../../../config/constants"
import noimage from "../../../../src/asset/img/team-thumb-2.png"

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    width: '20%',
    height: '500px',
    bgcolor: 'background.paper',
    border: '5px solid #478778',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
};
const ItemCard = (props) => {
    const [open, setOpen] = useState(true);
    const [isHovered, setIsHovered] = useState(false)
    const cartItemsContext = useContext(CartItemsContext)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => setOpen(false);

    const handleAddToCart = () => {
        cartItemsContext.addItemInCart(props.item, 1, '')
    }

    return (

        <div className="product__card__card" style={{display:props.item?.totalAmount > 0 ? '':'none'}}>
            <div className="product__card">
                <Link to={`/item/rph/${props.item?.id}`}>
                    <div className="product__image"
                         onMouseEnter={() => setIsHovered(true)}
                         onMouseLeave={() => setIsHovered(false)}
                    >
                        {props.item?.product_image1 ? <img src={props.item?.product_image1 ? props.item?.product_image1 : noimage} alt="item" className="product__img" style={{width:'auto',height:'auto'}}/> :
                            <img src={noimage} alt="item"  className="product__img" />}
                    </div>
                </Link>
                <div className="product__card__detail">
                    <Link to={`/item/rph/${props.item?.id}`}>
                        <div className="product__name">

                            {props.item?.product_name}

                        </div>
                        <div className="product__description">
                            <span>{props.item?.product_description} </span>
                        </div>
                        <div className="product__description" style={{color:'grey',fontSize:'0.9rem'}}>
                            <span>สินค้าคงเหลือ : {props.item?.totalAmount} </span>
                        </div>

                    <div className="product__price">

                        <span style={{float: 'left', width: '68%'}}> {(parseFloat(props.item?.product_price)).toLocaleString('th-TH', {
                            style: 'currency',
                            currency: 'THB'
                        })}</span>
                        <span>
                        <IconButton  sx={{
                            borderRadius: '20px',
                            width: '40px',
                            height: '40px'
                        }}>
                            <AddShoppingCartIcon sx={{width: '22px', height: '22px', color: 'black'}}/>
                        </IconButton>
                        </span>
                    </div>
                    </Link>
                </div>
            </div>



        </div>
    );
}

export default ItemCard;
